<template>
  <a-card>
    <!-- <a-tabs>
      <a-tab-pane key="1" tab="sass平台">
      </a-tab-pane>
      <a-tab-pane key="2" tab="私有化">
      </a-tab-pane>
    </a-tabs> -->
    <div class="mb15">
      <span class='mr15'>
        <a-button type="primary" v-has:codeSynOrder="allSynchronize">
          <PlusOutlined />
          同步生码单
        </a-button>
      </span>
    </div>
    <a-form ref='form' :model='search' :initialValues="search" :onFinish='pageSearchChange' layout='inline'>
      <a-form-item label="关键词">
        <a-input style="width: 270px" v-model:value="search.keywords" placeholder="生码单号、批次号、商户" />
      </a-form-item>
      <a-form-item label="关联类型" name='relevance_type'>
        <a-select v-model:value="search.relevance_type" :style="{width:'140px'}" placeholder="全部">
          <a-select-option value="">全部</a-select-option>
          <a-select-option value="1">前关联</a-select-option>
          <a-select-option value="2">后关联</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="码类型" name='code_type'>
        <a-select v-model:value="search.code_type" :style="{width:'140px'}" placeholder="全部">
          <a-select-option value="">全部</a-select-option>
          <a-select-option value="1">标准码</a-select-option>
          <a-select-option value="2">套标</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="场景类型" name='scenario_type'>
        <a-select v-model:value="search.scenario_type" :style="{width:'140px'}" placeholder="全部">
          <a-select-option value="">全部</a-select-option>
          <a-select-option value="1">万能码</a-select-option>
          <a-select-option value="2">提货码</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="状态" name='status'>
        <a-select v-model:value="search.status" :style="{width:'140px'}" placeholder="全部">
          <a-select-option value="">全部</a-select-option>
          <a-select-option value="1">同步完成</a-select-option>
          <a-select-option value="2">待同步</a-select-option>
          <a-select-option value="3">同步中</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" htmlType='submit'>搜索</a-button>
      </a-form-item>
    </a-form>
    <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='columns' :data-source='list' class="tableFixBox m-t-20" :scroll="{ x: 'max-content'}">
      <template v-slot:remarks="{ record }">
        <p style="max-width:300px">{{record.remarks || '-'}}</p>
      </template>
      <template v-slot:action="{ record }">
        <a-button v-if="record.code_type!=5" v-has:codeSynEditRemarks="()=>{listItem = record;showRemarks=true;remarks=record.remarks}" size="small" type="link">修改备注</a-button>
        <a-button v-if="record.code_type!=5" v-has:codeSyn="()=>codeSynchronize(record)" size="small" type="link">同步</a-button>
        <p v-else>-</p>
        <!-- <a v-has:codeSynUpCodePacket="()=>saveCodeBash(record)" href="javascript:;">上传码包</a> -->
      </template>
    </a-table>
    <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange" @showSizeChange="pageSizeChange" />
  </a-card>
  <a-modal v-model:visible="codeBashShow" title="上传码包" :footer="null" centered destroyOnClose>
    <a-form :label-col="{span:4}" :wrapper-col="{span:20}">
      <a-form-item label="品牌商信息">
        {{codeBashData.brand_company}}
      </a-form-item>
      <a-form-item label="批次信息">
        {{codeBashData.batch_sn}}
      </a-form-item>
      <a-form-item label="上传码包">
        <a-upload action="https://www.mocky.io/v2/5cc8019d300000980a055e76" v-model:file-list="fileList"  :multiple="false" :before-upload="beforeUpload">
          <a-button >
            上传
          </a-button>
        </a-upload>
      </a-form-item>
      <a-form-item :wrapper-col="{offset:4}">
        <a-button type="primary" @click="saveUpload" :loading="uploading">提交</a-button>
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="showRemarks" title="修改备注" centered @ok="editRemarks" :confirm-loading="confirmLoading" destroyOnClose>
    <a-textarea v-model:value="remarks" placeholder="请输入备注内容" :rows="4" />
  </a-modal>
</template>
<script>
import useTableSearch from "@/hooks/useTableSearch";
import { onMounted, ref } from "@vue/runtime-core";
import { getCodeList,cover_code, sync_batch_sn, sync_code_info,setCodeInfo } from "@/api/codeManage";
import { message } from "ant-design-vue";
import { $iscode } from "@/utils/app";
import { uploadOutlined } from "@ant-design/icons-vue";

const defSearch = {
  keywords: "",
  relevance_type: "",
  code_type: "",
  scenario_type: "",
  status: "",
  limit: 10,
};
export default {
  components: {  },
  setup() {
    let columns = [
      {
        title: "id",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "生码单号",
        dataIndex: "order_sn",
        key: "order_sn",
      },
      {
        title: "批次号",
        dataIndex: "batch_sn",
        key: "batch_sn",
      },
      {
        title: "商户",
        dataIndex: "brand_company",
        key: "brand_company",
        customRender: function ({ record }) {
          return record.brand_company + "(" + record.brand_id + ")";
        },
      },
      {
        title: "关联",
        dataIndex: "relevance_type_text",
        key: "relevance_type_text",
      },
      {
        title: "码类型",
        dataIndex: "code_type_text",
        key: "code_type_text",
      },
      {
        title: "场景",
        dataIndex: "scenario_type_text",
        key: "scenario_type_text",
      },
      {
        title: "套标",
        dataIndex: "standard_specification",
        key: "standard_specification",
      },
      {
        title: "码量",
        dataIndex: "code_counts_text",
        key: "code_counts_text",
      },
      {
        title: "状态",
        dataIndex: "sync_status_text",
        key: "sync_status_text",
      },
      {
        title: "备注",
        dataIndex: "remarks",
        key: "remarks",
        slots: { customRender: "remarks" },
      },
      {
        title: "更新时间",
        dataIndex: "last_change_time",
        key: "last_change_time",
      },
      {
        title: "操作",
        key: "action",
        fixed: 'right',
        slots: { customRender: "action" },
      },
    ];
    const {
      pageCurrentChange,
      pageSizeChange,
      search,
      list,
      initData,
      total,
      pageSearchChange,
      listLoading,
    } = useTableSearch({ data: defSearch });
    const tableHoosks = {
      pageCurrentChange,
      pageSizeChange,
      search,
      initData,
      list,
      total,
      pageSearchChange,
      listLoading,
    };
    onMounted(() => {
      tableHoosks.initData(getCodeList);
    });
    // 同步
    const codeSynchronize = async (data) => {
      const hide = message.loading("同步中...", 0);
      let res = await sync_code_info({batch_sn:data.batch_sn}).then(res=>res.data).catch((error) => error);
      $iscode(res,true)
      setTimeout(hide,100);
    }
    // 同步单号
    const allSynchronize = async () => {
      const hide = message.loading("同步中...", 0);
      let res = await sync_batch_sn()
        .then((res) => res.data)
        .catch((error) => error);
      $iscode(res, true);
      setTimeout(hide, 100);
    };
    // 修改备注
    const showRemarks = ref(false);
    const listItem = ref({});
    const remarks = ref('');
    const confirmLoading = ref(false);
    const editRemarks = async () => {
      const hide = message.loading("正在加载中...", 0);
      confirmLoading.value = true;
      let res = await setCodeInfo({batch_sn:listItem.value.batch_sn,brand_id:listItem.value.brand_id,remarks:remarks.value}).then((res) => res.data).catch((error) => error);
      setTimeout(hide, 1000);
      confirmLoading.value = false;
      if ($iscode(res)) {
        message.success(res.msg)
        tableHoosks.initData(getCodeList);
        showRemarks.value = false;
      }else{
        message.error(res.msg)
      }
    };
    //上传码包
    const codeBashData = ref({});
    const codeBashShow = ref(false);
    const fileList=ref([])
    const uploading=ref(false)
    const saveCodeBash = (data) => {
      codeBashData.value = data;
      codeBashShow.value = true;
    };
    const beforeUpload=(file)=>{
      fileList.value = [ file];
      return false
    }
    const saveUpload=async ()=>{
       const formData = new FormData();
        console.log(fileList.value)
       formData.append('code_file', fileList.value[0].originFileObj);
      formData.append('brand_id', codeBashData.value.brand_id);
      formData.append('batch_sn', codeBashData.value.batch_sn);
      uploading.value = true;
      let result=await cover_code(formData).then(res=>res.data).catch(error=>error)
      uploading.value = false;
      if($iscode(result,true)){
        codeBashShow.value = false;
        tableHoosks.initData(getCodeList);
      }
    }
    return {
      ...tableHoosks,
      columns,
      codeSynchronize,
      allSynchronize,
      saveCodeBash,
      codeBashShow,
      codeBashData,
      fileList,
      uploading,
      saveUpload,
      beforeUpload,
      remarks,
      showRemarks,
      listItem,
      editRemarks,
      confirmLoading
    };
  },
};
</script>