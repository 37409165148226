import config from '@/config'
import { $iscode } from '@/utils/app';
import { message } from 'ant-design-vue';
import { onMounted, reactive,ref, toRefs } from 'vue';
export default  (options)=>{
    const list =ref([])
    const total=ref(0)
    const allNum=ref(0)
    const search=ref(options.data)
    const apiFunction=ref(Function)
    const state=reactive({
        listLoading: false
    })
    const initData=async (api,data)=>{
      list.value = []
      apiFunction.value= api || apiFunction.value 
        search.value=data||search.value
      state.listLoading=true
      var result=await  apiFunction.value(search.value).then(res=>res.data).catch(error=>error)
      state.listLoading=false
      if ($iscode(result)) {
        list.value = result.data.data ||result.data.list ||result.data;
        total.value = result.data&&result.data.total || result.meta&&result.meta.total || '';
        allNum.value = result.data.total_num || result.scan_ex_total_num || 0
      } else {
        message.error(result.msg);
      }
      return result
    }
    // 分页当前页切换
    const pageCurrentChange = (page, pageSize) => {
        search.value.page = page;
        initData();
    };
    // 分页当前页显示多少条切换
    const pageSizeChange = (current, limit) => {
        search.value.page = 1;
        search.value.limit = limit;
        initData();
    };
       // 页面筛选项搜索
    const pageSearchChange = () => {
      search.value = { ...search.value, page: 1 };
      initData();
    };

    return{
        ...toRefs(state),
        pageCurrentChange,
        initData,
        pageSizeChange,
        pageSearchChange,
        search,
        list,
        total,
        allNum
    }

}
